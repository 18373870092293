<template>
  <div>
    <div class="container-fluid mt--10">
      <div class="row">
        <div class="col-12">
          <div class="mt-10">
            <div class="row justify-content-around footer">
              <div  class="col-sm-12 col-lg-2 mt-5 pt-2 pb-2 text-center">
                <router-link :to="{ name: 'home' }"><logo /></router-link>
              </div>
              <div  class="col-sm-6 col-lg-2 mt-4">
                <div class="list-title font-weight-bolder" style="margin-bottom:18px;">Shop</div>
                <div class="list-item">
                  <router-link :to="{path: '/products?cat=books'}" style="color: inherit;">
                    Books
                  </router-link>
                </div>
                <div class="mt-1 list-item">
                  <router-link :to="{path: '/products?cat=gardening'}" style="color: inherit;">
                    Garden
                  </router-link>
                </div>
                <div class="mt-1 list-item">
                  <router-link :to="{path: '/products?cat=kitchen-home'}" style="color: inherit;">
                    Kitchen<span class="space">_</span>&<span class="space">_</span>Home
                  </router-link>
                </div>
                <div class="mt-1 list-item">
                  <router-link :to="{path: '/products?cat=out-about'}" style="color: inherit;">
                    Out<span class="space">_</span>&<span class="space">_</span>About
                  </router-link>
                </div>
                <div class="mt-1 list-item">
                  <router-link :to="{path: '/products?cat=well-being'}" style="color: inherit;">
                    Well Being
                  </router-link>
                </div>
              </div>
              <div class="col-sm-6 col-lg-2 mt-4">
                <div class="list-title font-weight-bolder" style="margin-bottom:18px;">About Us</div>
                <div class="list-item">
                <div class="mt-1 list-item" @click="scrollToMission()">Mission</div>
                <router-link to="/contact-us">
                  <div class="mt-1 list-item">Contact Us</div>
                </router-link>
                 <div class="mt-1">
                   <router-link
                class="list-item"
                :to="{ path: '/privacy' }"
                replace
                >
                Privacy Policy
                </router-link>
                 </div>

                <div class="mt-1">
                  <router-link
                  class="list-item"
                  :to="{ path: '/cookies' }"
                  replace
                  >
                  Cookies
                  </router-link>
                </div>
                </div>
              </div>
                  <div class="col-sm-6 col-lg-2 mt-4">
                    <div data-v-1e98c8d4="" class="list-title font-weight-bolder" style="margin-bottom: 18px;">Follow Us</div>
                <a href="https://www.facebook.com/Plastic-Free-Europe-103667769016289">
                  <i class="fa fa-facebook-square" style="font-size: 24px"></i>
                </a>
                <a href="https://twitter.com/Plasticfree_eu">
                  <i class="fa fa-twitter-square ml-2" style="font-size: 24px"></i>
                </a>
                <a href="https://www.instagram.com/plastic_free_europe/">
                  <i
                  class="fa fa-instagram ml-2"
                  style="font-size: 24px"
                ></i>
                </a>
              </div>
              <div class="col-sm-6 col-lg-4 mt-4">
                <div class=" font-weight-bolder pr-4"><p class="m-0  list-title font-weight-bolder ">Stay Up To Date!</p></div>
                <div class="row pl-3 ">
                  <base-input
                    alternative=""
                    placeholder="Email"
                    input-classes="form-control mt-2 mr-1 border-silver email-input"
                    style="width : 240px;
                    font-family: Heebo;
                    font-size: 14px;
                    font-weight: normal;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: 1.5;
                    letter-spacing: normal;"
                    v-model="subscribeEmail"
                  />
                  <button style="margin-left: 10px;" class="btn btn-shop text-white text-nowrap p-2" @click="subscribe">SUBSCRIBE</button>
                  <div class="form-group d-flex">
                    <div class="form-check">
                      <input v-validate="'required'" class="form-check-input" name="privacy-policy" type="checkbox" id="policyLabel" v-model="acceptPolicy">
                      <label class="form-check-label" for="policyLabel">I agree to the <router-link style="color: #143f49;text-decoration: underline" to="/privacy">GDPR and data policy</router-link></label>
                    </div>
                    <p class="text-danger mb-0" v-if="errors.first('privacy-policy')"><small>You need to accept the GDPR and data policy</small></p>
                    <base-input
                      alternative=""
                      placeholder="Name"
                      input-classes="form-control mt-2 mr-1 border-silver email-input d-none"
                      style="width : 240px;
                      font-family: Heebo;
                      font-size: 14px;
                      font-weight: normal;
                      font-stretch: normal;
                      font-style: normal;
                      line-height: 1.5;
                      letter-spacing: normal;"
                      v-model="subscribeName"
                    />
                    <div class="loader mt-3 ml-2" v-if="subscribeLoading"></div>
                  </div>
                </div>
                <p class="mt-2">{{subscribeMessage}}</p>
                <div class="list-title">Accepted Payment Methods <i class="fa fa-question-circle fa mt-1  " aria-hidden="true"  style="font-size: 17px" title="These are the accepted payment methods."></i>
                </div>
                <div class="row ml-2 cards payment-methods">
                  <i class="fa fa-cc-visa" style="font-size: 32px"></i>
                  <i
                    class="fa fa-cc-mastercard ml-3"
                    style="font-size: 32px"
                  ></i>
                  <i
                    class="fa fa-cc-amex ml-3"
                    style="font-size: 32px"
                  ></i>

                </div>
              </div>

            </div>
            <div class="row bottom-section mt-2">
              <div class="col-6" style="font-size: 14px; font-weight: bold; color: #424242; font-family: 'Heebo'">
                Copyright © 2022 Plastic Free Europe | Powered by BøthOfUs AB
              </div>
              <div class="col-6" style="font-size: 10px; color: #424242; font-family: 'Heebo';">
                From A-Z, we take the hassle out of social and digital identity. We provide 100% organic website creation and social media management. Spreading your story to new customers & consumers
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import Logo from "@/components/Logo";
export default {

  name: "footer",
  components: { Logo },
  data() {
    return {
      subscribeEmail:"",
      subscribeMessage:"",
      subscribeLoading:false,
      siteKey: process.env.VUE_APP_RECAPTCHA_SITE_KEY,
      subscribeName: "",
      acceptPolicy: false,
    };
  },
  methods: {
    goto() {
      window.scrollTo(window.scrollX, window.scrollY - 300);
    },
    scrollToMission(){
     window.scrollBy({top:-300, behavior : "smooth"})
    },
    subscribe: function () {
      this.subscribeLoading = true;
      this.$validator.validateAll().then((result) => {
        if (!result) {
          this.subscribeLoading = false;
          return;
        }
        let url = 'subscribe';
        axios({
          method: "POST",
          url: url,
          data: {email: this.subscribeEmail, name: this.subscribeName},
          headers: {
            "content-type": "application/json",
          },
        }).then(
            (result) => {
              if (result) {
                this.subscribeLoading = false;
                this.subscribeEmail = "";
                this.subscribeMessage = result.data.message;
              }
            },
            (error) => {
              this.subscribeLoading = false;
              this.$noty.error('Not able to subscribe you currently.',{ closeWith : ['button']});
              console.log(error);
            }
        );
      });
    },
  },
};
</script>
<style scoped>
.space{
  color: transparent;
}
.footer {
  min-height: 392px;
  min-width: 100%;
  padding: 50px;
  margin-bottom: -50px;
  border-top-right-radius: 16px;
  border-top-left-radius: 16px;
  background-color: #deeddf;
  font-family: 'Heebo';
}
@media screen and (min-width:992px){
  .footer .follow{
    display : none;
  }
}
@media screen and (max-width:991px) {
  .footer .col-sm-6{
    text-align: center;
  }
  .payment-methods{
    margin-bottom: 64px !important;
  }
  .footer .follow{
    margin-bottom: 64px;
  }
  .footer .form-group{
    text-align: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
  }
  .footer{
    padding : 8px;
  }
  .footer .form-group{
    width: 100% !important;
  }
  .footer .btn-shop{
    margin-left: auto !important;
    margin-right: auto !important;
    margin-bottom: 16px;
  }
  .footer .cards{
        justify-content: center;
    margin: 16px;
  }
}
.follow{
      justify-content: space-around;
    display: flex;
}
.list-title {
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  font-family: 'Heebo' !important;
  color: #143f49 !important;
}

.list-item {
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  /*letter-spacing: normal;*/
  font-family: 'Heebo' !important;
  color: #143f49 !important;
  cursor: pointer;
}

.bottom-section{
  border-top-right-radius: 16px;
  border-top-left-radius: 16px;
  padding: 30px;
  background-color: #b1d5b5;
}

.footer  , .fa {
  color: #143f49;
}
i {
  color: #143f49;
}

.form-control::-webkit-input-placeholder {
  color: rgb(255, 0, 0);
}

.email-input {
  max-width: 254px;
}



.form-control::placeholder{
  font-family: Heebo;
  font-size: 14px !important;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #b9c3bc !important;
}

.btn-shop {
  font-family: Heebo;
  margin-top: 7px;
  background-color: #e7615a;
  border-color: #e7615a;
  width: 120px;
  height: 40px;
  object-fit: contain;
  font-size: 14px;
  font-weight: 500;
}
</style>

<style>
  .grecaptcha-badge{
    display:none !important;
  }
</style>
