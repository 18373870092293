import Vue from 'vue'
import Router from 'vue-router'
import DashboardLayout from '@/layout/DashboardLayout'
import AuthLayout from '@/layout/AuthLayout'

Vue.use(Router)

const router = new Router({
  linkExactActiveClass: 'active',
  mode: 'history',
  routes: [
    // {
    //   path: '/',
    //   redirect: 'dashboard',
    //   component: DashboardLayout,
    //   children: [
    //     {
    //       path: '/dashboard',
    //       name: 'dashboard',
    //       // route level code-splitting
    //       // this generates a separate chunk (about.[hash].js) for this route
    //       // which is lazy-loaded when the route is visited.
    //       component: () => import(/* webpackChunkName: "demo" */ './views/Dashboard.vue')
    //     },
    //     {
    //       path: '/profile',
    //       name: 'profile',
    //       component: () => import(/* webpackChunkName: "demo" */ './views/UserProfile.vue')
    //     },
    //     {
    //       path: '/cart-summary',
    //       name: 'Cart-Summary',
    //       component: () => import(/* webpackChunkName: "demo" */ './views/CartSummary.vue')
    //     },
    //     {
    //       path: '/checkout',
    //       name: 'Check-Out',
    //       component: () => import(/* webpackChunkName: "demo" */ './views/CheckOut.vue')
    //     },
    //     {
    //       path: '/cart',
    //       name: 'Cart',
    //       component: () => import(/* webpackChunkName: "demo" */ './views/Cart.vue')
    //     },
    //   ]
    // },
    {
      path: '/dashboard',
      redirect: 'dashboard',
      component: DashboardLayout,
      children: [
        {
          path: '/dashboard',
          name: 'dashboard',
          // route level code-splitting
          // this generates a separate chunk (about.[hash].js) for this route
          // which is lazy-loaded when the route is visited.
          component: () => import(/* webpackChunkName: "demo" */ './views/Dashboard.vue')
        },
        // {
        //   path: '/profile',
        //   name: 'profile',
        //   component: () => import(/* webpackChunkName: "demo" */ './views/UserProfile.vue')
        // },
        {
          path: '/payment',
          name: 'Payment',
          component: () => import(/* webpackChunkName: "demo" */ './views/Payment.vue')
        },
      ]
    },
    {
      path: '/login',
      redirect: 'login',
      component: AuthLayout,
     
      children: [
        {
          path: '/login',
          name: 'Login',
          component: () => import(/* webpackChunkName: "demo" */ './views/Login.vue'),
         
        },
        {
          path: '/signup',
          name: 'Sign Up',
          component: () => import(/* webpackChunkName: "demo" */ './views/Signup.vue')
        },
        {
          path: '/change-password',
          name: 'Change Password',
          component: () => import(/* webpackChunkName: "demo" */ './views/NewPassword.vue')
        },
        {
          path: '/reset-password',
          name: 'Reset Password',
          component: () => import(/* webpackChunkName: "demo" */ './views/ResetPassword.vue')
        },
        {
          path: '/confirm-email',
          name: 'Confirm Email',
          component: () => import(/* webpackChunkName: "demo" */ './views/ConfirmEmail.vue')
        },
      ]
    },
    {
      path: '/privacy',
      name: 'Privacy',
      component: () => import(/* webpackChunkName: "demo" */ './views/PrivacyPolicy.vue')
    },
    {
      path: '/cookies',
      // redirect: 'login',
      name: 'Cookies',
      component: () => import(/* webpackChunkName: "demo" */ './views/CookiePolicy.vue')

    },
    {
      path: '/cookiebanner',
      // redirect: 'login',
      name: 'cookiesBanner',
      component: () => import(/* webpackChunkName: "demo" */ './views/CookieBanner.vue')

    },
    {
      path: '/menu',
      // redirect: 'login',
      name: 'HeaderMenu',
      component: () => import(/* webpackChunkName: "demo" */ './views/HeaderMenu.vue')

    },
    {
      path: '/wishlist',
      // redirect: 'login',
      name: 'Wishlist',
      component: () => import(/* webpackChunkName: "demo" */ './views/WishList.vue')

    },
    {
      path: '/wishempty',
      // redirect: 'login',
      name: 'Wishlist Empty',
      component: () => import(/* webpackChunkName: "demo" */ './views/WishListEmpty.vue')

    },
    {
      path: '/',
      name: 'home',
      component: () => import(/* webpackChunkName: "demo" */ './views/HomePage.vue')
    },
    {
      path: '/profile',
      name: 'Your Profile',
      component: () => import(/* webpackChunkName: "demo" */ './views/AccountProfile')
    },
    {
      path: '/products',
      name: 'View Products',
      component: () => import(/* webpackChunkName: "demo" */ './views/Products.vue')
    },
    {
      path: '/product/:product_id',
      name: 'Product Details',
      component: () => import(/* webpackChunkName: "demo" */ './views/SingleProduct.vue')
    },
    {
      path: '/cart',
      name: 'View Cart',
      component: () => import(/* webpackChunkName: "demo" */ './views/Cart.vue')
    },
    {
      path: '/checkout',
      name: 'Check Out',
      component: () => import(/* webpackChunkName: "demo" */ './views/CheckOut.vue')
    },
    {
      path: '/cart-summary',
      name: 'Cart Summary',
      component: () => import(/* webpackChunkName: "demo" */ './views/CartSummary.vue')
    },
    {
      path: '/contact-us',
      name: 'Contact Us',
      component: () => import(/* webpackChunkName: "demo" */ './views/ContactUs.vue')
    },
    {
      path: '/order-success',
      name: 'Order Success',
      component: () => import(/* webpackChunkName: "demo" */ './views/OrderSuccess.vue')
    },
  ],
  scrollBehavior () {
    return { x: 0, y: 0 }
  }
});

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    const auth = localStorage.getItem("access_token");

    if (!auth) {
      next({
        path: "/login",
        query: {
          redirect: to.fullPath
        }
      });
    } else {
      next();
    }
  } else {
    const auth = localStorage.getItem("access_token");
    if(!auth){
      localStorage.removeItem('user');
      localStorage.removeItem('auth');
      localStorage.removeItem('access_token');
      localStorage.removeItem('access_token_expires_at');
      localStorage.removeItem('name');
      localStorage.removeItem('email');
      localStorage.removeItem('user_id');
    }
    next();
  }
});
router.beforeEach((to, from, next) => {
  document.title = to.name + " - Plastic Free Europe";
  if(window.$logEvent){
    window.$logEvent(window.analytics,'page_view');
  }
  next();
});

export default router;
