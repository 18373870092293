/*!

=========================================================
* Vue Argon Dashboard - v1.1.1
=========================================================

* Product Page: https://www.creative-tim.com/product/vue-argon-dashboard
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/vue-argon-dashboard/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import './registerServiceWorker'
import ArgonDashboard from './plugins/argon-dashboard'
import axios from "axios";
import VeeValidate from "vee-validate";
import VueNoty from "vuejs-noty";
// Import the functions you need from the SDKs you need
import {initializeApp} from "firebase/app";

// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyBk_Hltrc7HgEVNyRgkdJ9ADZPj8Qgmqrk",
    authDomain: "plasticfreeeurope.firebaseapp.com",
    projectId: "plasticfreeeurope",
    storageBucket: "plasticfreeeurope.appspot.com",
    messagingSenderId: "1004222491245",
    appId: "1:1004222491245:web:a894c882f018d83341ed64"
};

// Initialize Firebase
// eslint-disable-next-line no-unused-vars
const app = initializeApp(firebaseConfig);
window.Fire = new Vue()
import {getAnalytics,logEvent} from "firebase/analytics";

// eslint-disable-next-line no-unused-vars
const analytics = getAnalytics(app);


Vue.config.productionTip = false
Vue.prototype.$logEvent = logEvent;
Vue.prototype.$analytics = analytics;
window.analytics = analytics;
window.$logEvent = logEvent;

/*  axios.defaults.baseURL = "http://127.0.0.1:8000/api/"; // Local API URL */ 
axios.defaults.baseURL = process.env.VUE_APP_API_URL ? process.env.VUE_APP_API_URL : "https://be.plasticfreeeurope.org/api/"; // Live API URL
Vue.filter('truncate', function (text, length, suffix) {
    if (text.length > length) {
        return text.substring(0, length) + suffix;
    } else {
        return text;
    }
});
// Set The Auth Token For Users Site Wide
let token = localStorage.getItem('access_token');
axios.defaults.headers.common = {'Authorization': `Bearer ${token}`};

// Set the cart to store use data
if(localStorage.getItem('cart') === null){
    localStorage.setItem('cart', JSON.stringify([]));
}
// Set wishlist to store user data
if(localStorage.getItem('wishlist') === null){
    localStorage.setItem('wishlist', JSON.stringify([]));
}

// Set the order to store user order data
if(localStorage.getItem('order') === null){
    localStorage.setItem('order', JSON.stringify([]));
}

Vue.use(ArgonDashboard)
Vue.use(VeeValidate);
import 'vuejs-noty/dist/vuejs-noty.css';
Vue.use(VueNoty, {
  theme: "sunset",
  layout: "topRight",
  progressBar: true,
});
new Vue({
    router,
    render: h => h(App)
}).$mount('#app')
