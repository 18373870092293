<template>
    <div class="image">
       <HeaderMenu/>
        <div class="main-content">
            <!-- Header -->
            <div class="py-8"></div>
            <!-- Page content -->
            <div class="container mt--9">
                <slide-y-up-transition mode="out-in" origin="center top">
                    <router-view></router-view>
                </slide-y-up-transition>
            </div>
        </div>
      <Footer />
    </div>
</template>
<script>

import HeaderMenu from "../views/HeaderMenu";
import { SlideYUpTransition } from 'vue2-transitions'
import Footer from "../components/Footer";

  export default {
    name: 'auth-layout',
    components: {
      SlideYUpTransition,
      HeaderMenu,
      Footer
    },
    data() {
      return {
        year: new Date().getFullYear(),
        showMenu: false
      }
    }
  }
</script>
<style scoped>
.image{
  background-image: url('./bg-image.jpg') !important;
  background-repeat: no-repeat !important;
  background-size: 100% 68% !important;
}

.container {
  margin-bottom: 100px;
}

</style>
