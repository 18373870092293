<template>
  <div class="d-lg-flex justify-content-center bg-white headroom main-nav" :class="{'headroom--unpinned': scrolled}">
    <div class="row">
      <div class="col-md-12">
        <nav class="navbar navbar-expand-lg navbar-light bg-light ">
          <div class="container-fluid pl-lg-5 pr-lg-5">
            <span class="navbar-brand">
            <router-link :to="{ name: 'home' }"><logo /></router-link>
          </span>
            <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
              <span class="navbar-toggler-icon"></span>
            </button>
            <div class="collapse navbar-collapse overlay-nav" id="navbarSupportedContent">
              <div class="text-right">
                <i class="fas fa-close navbar-toggler"  data-toggle="collapse" data-target="#navbarSupportedContent"></i>
              </div>
              <ul class="navbar-nav ">
                <li
                    v-for="category in categories"
                    :key="category.path"
                    class="nav-item px-3"
                    :class="isActiveNav(category.path) ? 'active-nav' : ''"
                >
                  <router-link
                      class="text-decoration-none category-name"
                      :to="'/products?cat=' + category.path"
                  >{{ category.name }}</router-link
                  >
                </li>
              </ul>
              <div class="d-flex align-items-center justify-content-around search-box has-search">
                <div class="form-inline my-2 mr-3 my-lg-0 d-none d-sm-block">
                  <div class="input-group border-0 border-white shadow-none">
                    <div class="input-group-prepend">
                  <span class="input-group-text input-group-addon-color border border-right-0  " id="basic-addon1">
                    <span class="fa fa-search "></span>
                  </span>
                    </div>
                    <input
                        class="form-control mr-sm-2 border-left-0 border custom-border mw-100 pl-3"
                           type="search"
                           id="bootstrap-overrides"
                        placeholder="Search for spoon..."
                        v-model="searchText"
                        @blur="changeSearchText"
                        @keyup.enter="searchProduct"
                        aria-label="Search">

                  </div>
                </div>
                <span v-if="shoppingAs !== ''" @click="toggleShoppingAs" class="mr-3 btn btn-success btn-sm">{{shoppingAs}}</span>
                <router-link
                    class="text-reset text-decoration-none"
                    :to="{ path: '/wishlist' }"
                    replace
                >
              <span class=""
              ><i
                  id="top-heart"
                  :class="{ 'red-heart': wishlist_items.length > 0 }"
                  class="fas fa-heart text-center mr-2"
              ></i
              ></span>
                </router-link>
                <a
                    v-on:click="cart.visible = !cart.visible"
                    class="
                          text-reset text-decoration-none
                          align-content-center
                          d-flex
                        "
                    href="#"
                    role="button"
                    @click.prevent="getCart"
                >
                  <i
                      id="top-cart"
                      class="fas fa-shopping-cart"
                  ></i
                  >&nbsp;&nbsp;
                  <span class="badge badge-success ml-2 cart-badge" style="font-size: 12px">{{
                      cartQuantity
                    }}</span>
                </a>

                <div class="ml-4 user-detail d-flex flex-row" v-if="isLoggedIn">
                  <router-link :to="{ path: '/profile' }">
                    <div
                        class="row username ml-1"
                        :class="{ 'red-profile': isAtProfilePage }"
                        style="width: 120px"
                    >
                      <i
                          class="fas fa-user mr-2"
                          :class="{ 'red-profile': isAtProfilePage }"
                      ></i>
                      {{
                        getLoggedInUserName().length > 8
                            ? getLoggedInUserName().substr(0, 8) + "&hellip;"
                            : getLoggedInUserName()
                      }}
                    </div>
                  </router-link>
                </div>
                <router-link
                    v-else
                    class="text-reset text-decoration-none ml-4"
                    :to="{ path: '/login' }"
                >
                  <button
                      type="button"
                      class="
                  btn btn-login
                  text-white text-nowrap
                  font-weight-normal
                  p-1
                "
                  >
                    LOG IN
                  </button>
                </router-link>
              </div>
            </div>

          </div>
        </nav>
        <div v-if="visible">
          <div>
            <nav class=" navbar navbar-vertical fixed-right  navbar-expand-md navbar-light bg-white "
                 id="sidenav-main">
              <div class="container-fluid justify-content-start shopping-cart">
                <header class="row cart-header">
                  <div class="col-12 text-center py-4">
                    <div
                        class="d-flex align-items-center justify-content-center "
                    >
                      <h1
                          class="
                            cart-title
                            d-inline-block
                            site-title
                            text-left
                            mb-0
                            py-2
                          "
                          style="margin-right: 60px"
                      >
                        Shopping Cart ({{ products.length }})
                      </h1>
                      <img
                          src="/img/icons/close-cart.svg"
                          class="d-inline-block ml-3 cursor-pointer"
                          @click="getCart"
                          id="close"
                      />
                    </div>
                  </div>
                </header>
                <section class="row mb-5" style="">
                  <div class="col-12 py-4">
                    <div class="table-responsive">
                      <table class="table">
                        <tr v-for="(item, index) in products" :key="index">
                          <td>
                            <h3>
                              <i
                                  class="fas fa-times cursor-pointer"
                                  @click="removeFromCart(index)"
                              ></i>
                            </h3>
                          </td>
                          <td>
                            <img
                                :src="appURL + item.image"
                                @error="imageError"
                                height="80"
                                width="80"
                            />
                          </td>
                          <td>
                            <p class="product-name text-wrap">
                              {{ item.name }}
                            </p>
                            <p class="product-price">€{{ item.price }}</p>
                            <p>
                              Qty:
                              <input
                                  class="qty-input form-control-sm w-50"
                                  type="number"
                                  v-model="products[index].quantity"
                                  @change="
                                    updateQuantity(
                                      index,
                                      products[index].quantity
                                    )
                                  "
                              />
                            </p>
                          </td>
                          <td>
                            <p class="product-subtotal">
                              €{{
                                parseFloat(
                                    item.price * item.quantity
                                ).toFixed(2)
                              }}
                            </p>
                          </td>
                        </tr>
                        <tfoot>
                        <tr>
                          <td colspan="2">&nbsp;</td>
                          <td style="padding-top: 18px !important">
                            <h3 class="text-right text-dark">Subtotal</h3>
                          </td>
                          <td style="padding-top: 18px !important">
                            <h3 class="text-dark">
                              € {{ parseFloat(total).toFixed(2) }}
                            </h3>
                          </td>
                        </tr>
                        </tfoot>
                      </table>
                    </div>
                  </div>
                </section>

                <div v-if="total < (shoppingAs === 'individual' ? 50 : 250)  && userEmail !== 'dimitra@bothofus.se'" class="row">
                  <div
                      class="
                        w-100
                        d-flex
                        flex-row
                        justify-content-center
                        align-items-center
                        text-danger
                      "
                  >
                    <i
                        class="fa fa-exclamation-triangle mr-2"
                        aria-hidden="true"
                    ></i>
                    You must have a minimum of €{{shoppingAs === 'individual' ? 50 : 250}}.00 to place your order.
                  </div>
                </div>
                <footer
                    class="row"
                    style="padding-left: 60px; padding-right: 60px"
                >
                  <div class="col-12 mt-5 px-5">
                    <p class="px-3">
                      <button
                          :disabled="(total < (shoppingAs === 'individual' ? 50 : 250) && userEmail !== 'dimitra@bothofus.se') || products.length < 1"
                          type="button"
                          class="btn btn-danger btn-block"
                          @click="createOrder"
                      >
                        GO TO CHECKOUT
                      </button>
                    </p>
                    <p class="small-info text-center">
                      Shipping and taxes are calculated at checkout.
                    </p>
                    <p class="text-center">
                        <span
                            class="cursor-pointer text-underline"
                            @click="visible = !visible"
                        >CLOSE</span
                        >
                    </p>
                  </div>
                  <!-- <div v-else class="col-12 mt-5 px-5">
                    <p class="small-info text-center">You need to be logged in to checkout</p>
                    <button type="button" class="btn btn-danger btn-block" @click="$router.push('/login')">LOG IN</button>
                  </div> -->
                </footer>
              </div>
            </nav>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import Logo from "../components/Logo.vue";
import router from "../router";
import axios from "axios";
import Vue from "vue";
// import SideBar from "../components/SidebarPlugin/SideBar.vue";
export default {
  components: { Logo },
  name: "headerMenu",
  data: function () {
    return {
      shoppingAs: sessionStorage.getItem("individual_or_business") || "",
      appURL: process.env.VUE_APP_MAIN_URL,
      menuShown: false,
      url: "",
      categories: [
        { name: "Kitchen & Home", path: "kitchen-home" },
        { name: "Books", path: "books" },
        { name: "Well Being", path: "well-being" },
        { name: "Gardening", path: "gardening" },
        { name: "Out & About", path: "out-about" },
      ],
      visible: false,
      cart: [],
      subTotal: 0.0,
      searchText: localStorage.getItem("search"),
      total: 0.0,
      products: [],
      cartQuantityData: 0,
      wishlist_items: [],
      limitPosition: 500,
      scrolled: false,
      lastPosition: 0,
      userEmail: localStorage.getItem('email') || ''
    };
  },
  methods: {
    getWishList: function () {
      // const token = localStorage.getItem("access_token");
      // if (token) {
      //   let url = `user/wishlist?access_token=${localStorage.getItem(
      //       "access_token"
      //   )}`;
      //   axios({
      //     method: "GET",
      //     url: url,
      //     headers: {
      //       "content-type": "application/json",
      //     },
      //   }).then(
      //       (result) => {
      //         if (result) {
      //           this.wishlist_items = result.data.wishlist_items;
      //           this.loading = false;
      //           this.wishlist_items.forEach((product) => {
      //             product.deleting = false;
      //           });
      //         }
      //       },
      //       (error) => {
      //         console.log(error);
      //         this.loading = false;
      //       }
      //   );
      // }
      // else {
        let url = `offline/wishlist`;
        let wishlist_items = localStorage.getItem("wishlist_items");
        if (wishlist_items) {
          this.wishlist_items = JSON.parse(wishlist_items);
        } else {
          this.wishlist_items = [];
        }

        axios({
          method: "POST",
          url: url,
          data: {
            wishlist_items: wishlist_items || [],
          },
          headers: {
            "content-type": "application/json",
          },
        }).then(
            (result) => {
              if (result) {
                this.wishlist_items = result.data.wishlist_items;
                this.loading = false;
              }
            },
            (error) => {
              console.log(error);
              this.loading = false;
            }
        );
      // }
    },
    changeSearchText: function () {
      localStorage.setItem("search", this.searchText);
    },

    getLoggedInUserName: function () {
      let computedName = "";
      const email = localStorage.getItem("email");
      const name = localStorage.getItem("name");
      if (name === "null" || !name) {
        computedName = email.split("@")[0].slice(0, 6);
        if (email.split("@")[0].length > 6) {
          computedName += "..";
        }
      } else {
        computedName = name;
      }
      return computedName;
    },
    isActiveNav: function (category) {
      if (this.$route.name === "products") {
        const queries = this.$route.query;
        if (Object.keys(queries).includes("cat")) {
          return queries["cat"] === category;
        }

        return false;
      }
      return false;
    },
    commitCart() {
      localStorage.setItem("cart", JSON.stringify(this.products));
      this.total = 0.0;
      this.products.forEach((product) => {
        this.total += product.price * product.quantity;
      });
      this.getCartQuantity();
    },
    getCart(close = true) {
      if (close) {
        this.visible = !this.visible;
      }
      if (this.visible) {
        this.$logEvent(this.$analytics,"view_cart");
        this.products = JSON.parse(localStorage.getItem("cart"));
        this.total = 0.0;
        this.products.forEach((product) => {
          this.total += product.price * product.quantity;
        });
      }
    },
    updateQuantity(index, quantity) {
      if (quantity >= 1) {
        this.products[index].quantity = quantity;
      } else {
        this.products.splice(index, 1);
      }
      this.commitCart();
    },
    removeFromCart(index) {
      this.products.splice(index, 1);
      this.commitCart();

      this.$logEvent(this.$analytics,"remove_from_cart");
    },
    imageError: function (e) {
      e.target.src =
          "https://be.plasticfreeeurope.org/emailImages/pfe-logo.png";
    },
    createOrder() {
      this.$logEvent(this.$analytics,"begin_checkout");
      this.$logEvent(this.$analytics,"user_engagement");
      router.push({ path: "/checkout" });
    },
    searchProduct: function () {
      localStorage.setItem("search", this.searchText);

      if(this.$route.name !='View Products') {

        this.$router.push('/products');
      }else{
        this.$router.go("/products");
      }




    },
     handleScroll() {
      if (this.lastPosition < window.scrollY && this.limitPosition < window.scrollY) {
        this.scrolled = true;
        // move up!
      }

      if (this.lastPosition > window.scrollY) {
        this.scrolled = false;
        // move down
      }

      this.lastPosition = window.scrollY;
      // this.scrolled = window.scrollY > 250;
    },
    getCartQuantity() {
      this.cartQuantityData = 0;
      let cart = JSON.parse(window.localStorage.getItem("cart"));
      cart.map((singleItem) => {
        this.cartQuantityData += parseInt(singleItem.quantity);
      });
    },
  },
  created() {
    window.addEventListener("scroll", this.handleScroll);
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  mounted() {
    this.getCartQuantity();
    this.getWishList();
      // eslint-disable-next-line no-undef
    Fire.$on("added_to_wishlist", () => {
      this.getWishList();
    });
    // eslint-disable-next-line no-undef
    Fire.$on("update-cart-quantity", () => {
      this.getCartQuantity();
    });

    // eslint-disable-next-line no-undef
    Fire.$on("individual_or_business_selected", (value) => {
      sessionStorage.setItem("individual_or_business", value)
      this.shoppingAs = sessionStorage.getItem("individual_or_business")
    });
  },
  watch: {
    $route() {
      this.$nextTick(() => {
        if (this.$route.path !== '/products') {
          this.searchText = ''
          localStorage.setItem('search', '')
        }
      });
    },
    menuShown: function (val) {
      if (val) {
        document.getElementsByTagName("html")[0].classList.add("no-scroll");
      } else {
        document.getElementsByTagName("html")[0].classList.remove("no-scroll");
      }
    },
  },
  computed: {
    cartQuantity() {
      return this.cartQuantityData > 0 ? this.cartQuantityData : "";
    },
    isLoggedIn() {
      const tokenExpiry = localStorage.getItem("access_token_expires_at");

      if (!tokenExpiry) {
        return false;
      }

      const tokenExpiryTime = moment(
          localStorage.getItem("access_token_expires_at")
      );
      const today = moment();

      if (today.diff(tokenExpiryTime) < 0) {
        return true;
      } else {
        localStorage.removeItem("access_token");
        localStorage.removeItem("access_token_expires_at");
        localStorage.removeItem("name");
        return false;
      }
    },
    isAtWishlistPage() {
      if (this.$route.name == "wishlist") {
        return true;
      }
      return false;
    },
    isAtProfilePage() {
      if (this.$route.name == "profile") {
        return true;
      }
      return false;
    },
  },
};

Vue.mixin({
  methods: {
    toggleShoppingAs: function (){
      // eslint-disable-next-line no-undef
      Fire.$emit('toggle_shopping_as', this.shoppingAs);
    },
  }
})
</script>

<style lang="scss" scoped>
@import url("https://fonts.googleapis.com/css2?family=Heebo&display=swap");
.red-heart {
  color: #e7615a !important;
}
.red-profile {
  color: #e7615a !important;
}
.navbar{
  background-color: white !important;
  height: 120px;
}
.header-menu {
  box-shadow: 0 2px 10px -2px rgba(185, 195, 188, 0.35) !important;
  // max-width: 81.25rem;
  height: 7.625rem !important;
}

.category-name {
  font-size: 14px !important;
  color: #424242 !important;
}

.active-nav {
  border-bottom: 1px solid #e7615a;
  padding-bottom: 8px;
  a {
    color: #e7615a !important;
  }
}

.nav-item a {
  color: #424242;
}

.btn-login {
  background-color: #e7615a;
  border-color: #e7615a;
  box-shadow: 2px 2px 8px -2px rgba(196, 82, 77, 0.4);
  width: 65px;
  height: 41px;
  object-fit: contain;
  font-size: 14px;
  font-weight: bold;
  font-family: "Heebo";
}

.form-group {
  display: inline-block;
}

.fa-search {
  font-size: 18px;
  //  margin-top: 30px;
}

.has-search input {
  border-radius: 4px;
  box-shadow: inset 0 1px 6px 0 rgba(185, 195, 188, 0.4);
  border: solid 1px #b9c3bc !important;
  font-size: 14px;
}
.has-search .input-group-text {
  box-shadow: inset 0 1px 6px 0 rgba(185, 195, 188, 0.4) !important;
  border: solid 1px #b9c3bc !important;
  color: black;
}

.has-search .form-control {
  // width: 225px;
  height: 41px;
  border-left: none !important;
  font-size: 14px;
}

.has-search .form-control-feedback {
  padding: 1px;
  position: absolute;
  z-index: 2;
  width: 2.375rem;
  height: 2.375rem;
  line-height: 2.375rem;
  text-align: center;
  pointer-events: none;
  color: #424242;
}
.fa-shopping-cart,
.fa-user {
  font-size: 20px;
}
.fa-heart {
  font-size: 20px;
}

.menu {
  height: 122px;
  background-color: #fff;
  width: 100%;
  font-family: "heebo";
  font-size: 14px;
}

@media only screen and (max-width: 600px) {
  .has-search {
    padding-bottom: 20px !important;
  }
  .custom-search{
    width: 250px !important;
  }

  .overlay-nav {
    background-color: #f6f6f6 !important;
    z-index: 999;
    padding: 2rem;
    li {
      list-style-type: none;
      text-align: center;
      margin: 1rem;
      text-transform: uppercase;
      font-weight: bold;
      border-bottom: 1px solid #eee;
      padding-bottom: 16px;
    }
    .fa-close {
      font-size: 1rem;
    }
  }
}

.custom-border {


  border-radius: 10px;
  box-shadow: inset 0 1px 6px 0 rgba(185, 195, 188, 0.4);
  border: solid 1px #b9c3bc;
}



#search-box input {
  columns: green;
}

#search-box input::placeholder {
  color: #b9c3bc !important;
  font-size: 14px !important;
}

#top-heart,
#top-cart,
.fa-user {
  color: #424242;
}
#top-heart{
    position: relative;
    top: 2px;
    left: -8px;
}
.username {
  font-size: 16px;
  font-weight: bold;
  color: #424242;
}
#close {
  z-index: 1005 !important;
}
#sidenav-main {
  z-index: 1001 !important;
  height: 100vh !important;
}
#sidenav-main header {
  background-color: #deeddf;
}
#sidenav-main header h1 {
  color: #424242;
}
table {
  color: #424242;
  font-size: 16px;
}
table p {
  font-family: "Heebo", sans-serif;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  letter-spacing: normal;
  color: #424242;
}
table td {
  vertical-align: middle;
  border-top: none;
  border-bottom: 1px solid #e9ecef;
}
table th {
  font-family: "Heebo", sans-serif;
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.22;
  letter-spacing: normal;
  text-align: right;
  color: #424242;
}
table input {
  border-radius: 4px;
  box-shadow: inset 0 1px 5px 0 rgba(185, 195, 188, 0.4);
  border: solid 1px #b9c3bc;
}

.product-name {
  font-size: 16px;
  font-weight: bold;
  color: #424242;
}

.qty-input {
  width: 75px !important;
  height: 32px;
  padding: 6px 11px 4px;
  border-radius: 4px;
  box-shadow: inset 0 1px 5px 0 rgba(185, 195, 188, 0.4);
  border: solid 1px #b9c3bc;
  background-color: #fff;
  font-family: "Heebo";
  font-size: 14px;
}

#sidenav-main .container-fluid {
  padding-left: 15px;
  padding-right: 15px;
}

.cart-title {
  font-size: 24px;
}

.product-subtotal,
.product-price {
  font-size: 16px;
  font-weight: normal;
}
.cart-badge{
      font-size: 12px;
    position: relative;
    left: -20px;
    top: -12px;
    padding: 4px;
    background: #e7615a;
    border-radius: 8px;
    color: white;
    font-weight: 300;
    font-size: 10px;
    font-family: 'Heebo';
    vertical-align: middle;
    display: flex;
    align-items: center;
    font-weight: 400;
}
</style>
